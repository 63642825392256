import React, { Component } from "react";

import Gallery from "./Gallery";
import sleeping from "../images/sleeping.webp";
import games from "../images/hmm3.webp";
import dog from "../images/dog.webp";

export default class Interests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tiles: Array.isArray(this.props.interests)
        ? this.props.interests.map(interest => ({
            title: interest.name,
            image: interest.image || this.extractImage(interest.name)
          }))
        : []
    };
  }

  extractImage = name => {
    return name === "Sleeping"
    ? sleeping

    : name === "Dogs"
    ? dog

    : name === "Gaming"
    ? games

    : name === "Travelling"
    ? `https://loremflickr.com/640/480/travel`

    : `https://loremflickr.com/640/480/${name}`;
  };

  render() {
    return <Gallery tiles={this.state.tiles} />;
  }
}
