import React from "react";
import "./PlaylistPage.css";
import ReactPlayer from 'react-player'

export default ({ playlistLink, title }) => (
  <div>
    <p className="headline" style={{ color: "Black" }}>
      {title}
    </p>
    <div className="video-container">
      <ReactPlayer
        url={playlistLink}
        light // lighthouse LCP error on mobile: https://github.com/GoogleChrome/lighthouse/issues/10869
        width="100%"
        height="100%"
        className="video"
        />
    </div>
  </div>
);
