import React from "react";
import { GridList, GridTile } from "material-ui/GridList";

export default ({ tiles }) => {
  if (!Array.isArray(tiles)) {
    return <span />;
  }

  return (
    <GridList>
      {tiles.map((tile, index) => (
        <GridTile
          key={index}
          title={tile.title}
        >
          <img src={tile.image} alt={tile.title} />
        </GridTile>
      ))}
    </GridList>
  );
};
